'use client';

import dynamic from 'next/dynamic';
import Link from 'next/link';
import useGoRouter from '@/hooks/useGoRouter';

const LanguageSwitcher = dynamic(
  () => import('@/components/UI/LanguageSwitcher'),
);

function RightBtnArea({ t }) {
  const { goRouterPay, goRouterConsole, loginArea, startArea } = useGoRouter();

  return (
    <>
      <LanguageSwitcher t={t} />
      <Link
        href={goRouterPay}
        className="hidden rounded-[26px] px-5 py-2 text-center text-gray-900 hover:text-gray-800 hover:underline md:text-base lg:block"
      >
        {t[loginArea]}
      </Link>

      <Link
        href={goRouterConsole}
        className="hidden whitespace-nowrap rounded-[26px] bg-primary-900 px-4 py-[5px] text-center text-xs text-white hover:bg-[#001963] md:py-2 md:text-base lg:block"
      >
        {t[startArea]}
      </Link>
    </>
  );
}

export default RightBtnArea;
