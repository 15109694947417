import request from '@/utils/request';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { ProjectId } from '@/interface/interface';

const URL = '/api/user';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Users', 'ProjectUsers'],
  endpoints: (builder) => ({
    queryUserPhotoBriefByProjectId: builder.query<any, ProjectId>({
      query: ({ ProjectId }) => ({
        url: `${URL}/queryUserPhotoBriefByProjectId`,
        method: 'GET',
        params: { ProjectId },
      }),
      providesTags: (result, error, arg) => [
        { type: 'ProjectUsers', id: arg.ProjectId },
      ],
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.ProjectId !== previousArg?.ProjectId;
      },
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const { useQueryUserPhotoBriefByProjectIdQuery } = usersApi;

export function register(data) {
  return request({
    url: `${URL}/register`,
    method: 'POST',
    data,
  });
}

export function login(data) {
  return request({
    url: `${URL}/login`,
    method: 'POST',
    data,
  });
}

export function thirdLogin(data) {
  return request({
    url: `${URL}/thirdLogin`,
    method: 'POST',
    data,
  });
}

export function self() {
  return request({
    url: `${URL}/self`,
  });
}

export function logout() {
  return request({
    url: `${URL}/logout`,
    method: 'POST',
  });
}

export function resendVerifyEmail(data) {
  return request({
    url: `${URL}/resendVerifyEmail`,
    method: 'POST',
    data,
  });
}

export function resetPasswordByVerifyToken(data) {
  return request({
    url: `${URL}/resetPasswordByVerifyToken`,
    method: 'POST',
    data,
  });
}

export function verifyEmailByVerifyToken(VerifyToken) {
  return request(`${URL}/VerifyEmailByVerifyToken?VerifyToken=${VerifyToken}`);
}

export function requestResetPassword(data) {
  return request({
    url: `${URL}/requestResetPassword`,
    method: 'POST',
    data,
  });
}

export function completeUserProfile(data) {
  return request({
    url: `${URL}/completeUserProfile`,
    method: 'PUT',
    data,
  });
}

export function updateUserPhoto(data) {
  return request(`${URL}/updateUserPhoto`, {
    method: 'PUT',
    data,
  });
}

export function updateUserProfile(data) {
  return request({
    url: `${URL}/UpdateUserProfile`,
    method: 'PUT',
    data,
  });
}

export function queryUserPhotoBriefByProjectId(ProjectId: number) {
  return request({
    url: `${URL}/queryUserPhotoBriefByProjectId?ProjectId=${ProjectId}`,
    method: 'GET',
  });
}
