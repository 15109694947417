import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

const codeMessage: { [key: number]: string } = {
  400: '請確認帳號或密碼是否有誤。',
  401: '沒有權限或Token過期。',
  403: '禁止存取，請與管理者確認權限。',
  404: '請檢查連線資訊是否正確。',
  410: '資源已被刪除，無法存取。',
  500: '伺服器異常，請聯絡管理員。',
  502: '伺服器連線錯誤。',
  503: '系統暫時無法使用，維護作業中。',
  504: '伺服器連線逾時。',
};

const errorHandler = (error: AxiosError) => {
  if (error.response) {
    const errorText =
      codeMessage[error.response.status] || error.response.statusText;
    if (error.response.status === 401) {
      window.localStorage.removeItem('token');
    } else {
      console.log(errorText);
    }
  }
  return Promise.reject(error);
};

// 創建 axios 實例
const instance = axios.create();

interface CustomAxiosRequestHeaders extends AxiosRequestHeaders {
  ClientType: string;
  Authorization: string;
  GroupId: string;
  Timezone: string;
}
// request 攔截器
instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    const groupId = localStorage.getItem('groupId');

    config.headers = {
      ...config.headers,
      ClientType: '3', // web
      Authorization: token ? `Bearer ${token}` : '',
      GroupId: groupId || '',
      Timezone:Intl.DateTimeFormat().resolvedOptions().timeZone
    } as CustomAxiosRequestHeaders;

    return config;
  },
  (error: AxiosError) => {
    errorHandler(error);
    return Promise.reject(error);
  },
);

// response 攔截器
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // 2xx 範圍內的狀態碼會觸發該函數。
    return response;
  },
  (error: AxiosError) => {
    // 超出 2xx 範圍的狀態碼會觸發該函數。
    errorHandler(error);
    return Promise.reject(error);
  },
);

export default instance;
