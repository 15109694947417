import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/src/assets/icons/facebook-icon.svg");
import(/* webpackMode: "eager" */ "/app/src/assets/icons/GB-banner-icon.png");
import(/* webpackMode: "eager" */ "/app/src/assets/icons/youtube-icon.svg");
import(/* webpackMode: "eager" */ "/app/src/components/UI/Button/ButtonTryFree.jsx");
import(/* webpackMode: "eager" */ "/app/src/components/UI/Navbar.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/UI/SmartAppBanner.jsx");
import(/* webpackMode: "eager" */ "/app/src/hooks/useCheckAuthPath.js");
import(/* webpackMode: "eager" */ "/app/src/hooks/useCheckCustomerStory.js");
import(/* webpackMode: "eager" */ "/app/src/hooks/useCheckHelpCenter.js")