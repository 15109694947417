import request from '@/utils/request';

// 包裝原有的 Axios request 給 RTKQuery 使用
export const axiosBaseQuery = () => async (args) => {
  const { url, method, data, params } = args;
  try {
    const result = await request({
      url,
      method,
      data,
      params,
    });
    return { data: result };
  } catch (axiosError) {
    return {
      error: {
        status: axiosError.response?.status,
        data: axiosError.response?.data,
      },
    };
  }
};
